import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "./App.css";

const R = require("ramda");

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function Admin(props) {
  const classes = useStyles();
  var rows = null;
  var count = null;
  if (props.details) {
    count = props.details.rowCount;
    const adminSort = R.sortWith([
      R.descend(R.propOr(0,"APICount")),
      R.descend(R.prop("LastAccessTimestamp")),
    ]);
    rows = adminSort(props.details.Items);
  }
  console.log("count" + count);
  return (
    <div>
      {rows && (
        <div>
          <p>
            Total users count: {count}, table rows: {rows.length}
          </p>
          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>UserID</TableCell>
                  <TableCell align="right">Message</TableCell>
                  <TableCell align="right">ReadCount</TableCell>
                  <TableCell align="right">StoreCount</TableCell>
                  <TableCell align="right">APICount</TableCell>
                  <TableCell align="right">LastAccessTimestamp</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.UserID}>
                    <TableCell component="th" scope="row">
                      {row.UserID}
                    </TableCell>
                    <TableCell align="right">{row.Message}</TableCell>
                    <TableCell align="right">
                      {row.ReadCount ? row.ReadCount : 0}
                    </TableCell>
                    <TableCell align="right">
                      {row.StoreCount ? row.StoreCount : 0}
                    </TableCell>
                    <TableCell align="right">
                      {row.APICount ? row.APICount : 0}
                    </TableCell>
                    <TableCell align="right">
                      {row.LastAccessTimestamp}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
}

export default Admin;
