import { MessageTypes } from "./MessageTypes.js";
const { CognitoIdentityClient } = require("@aws-sdk/client-cognito-identity");
const {
  fromCognitoIdentityPool,
} = require("@aws-sdk/credential-provider-cognito-identity");
const { LambdaClient, InvokeCommand } = require("@aws-sdk/client-lambda");

// Set the AWS Region.
const REGION = "eu-west-1";

function GetUserDetails(userid, accesstoken) {
  console.log(
    "GetUserDetails for user:" +
      userid +
      ", accesstoken:" +
      accesstoken +
      ", pool:" +
      process.env.REACT_APP_APP_ID
  );

  return new Promise((resolve, reject) => {
    const lambda = new LambdaClient({
      region: REGION,
      credentials: fromCognitoIdentityPool({
        client: new CognitoIdentityClient({ region: REGION }),
        identityPoolId: process.env.REACT_APP_POOL_ID,
        logins: {
          "www.amazon.com": accesstoken,
        },
      }),
    });

    //AWS.config.credentials.refresh(function () {

    var invokeRequest = {
      FunctionName: "tonyAlexaSkill",
      InvocationType: "RequestResponse",
      LogType: "None",
      Payload: JSON.stringify({
        message_type: MessageTypes.GET_DETAILS,
        userid: userid,
      }),
    };

    lambda.send(new InvokeCommand(invokeRequest), function (error, data) {
      if (error) {
        console.log(error);
        resolve(null);
      } else {
        console.log("GetUserDetails: Submit success");
        const asciiDecoder = new TextDecoder('ascii');
        const pullResults = JSON.parse(asciiDecoder.decode(data.Payload));
        resolve(pullResults);
      }
    });

  });
}

export default GetUserDetails;
