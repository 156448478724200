import React, { Component } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import SettingsIcon from "@material-ui/icons/Settings";
import SvgIcon from "@material-ui/core/SvgIcon";
import Typography from "@material-ui/core/Typography";
import { ReactComponent as ANMIcon } from "./anm.svg";
import { withRouter } from "react-router-dom";
import { UserContext, UserTypes } from "./Context.js";
import LogoutButton from "./LogoutButton.js";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";

require("dotenv").config();

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
});

class MainMenu extends Component {
  static contextType = UserContext;

  onMenuIconClick = (path) => {
    this.props.history.push(path);
  };

  render() {
    const { classes } = this.props;
    const { type, nodeRef } = this.context;

    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <SvgIcon
              component={ANMIcon}
              className={classes.menuButton}
              onClick={this.onMenuIconClick.bind(this, "/")}
            />
            {type !== UserTypes.NONE && (
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                onClick={this.onMenuIconClick.bind(this, "/developer")}
              >
                <SettingsIcon />
              </IconButton>
            )}
            {type === UserTypes.ADMIN && (
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                onClick={this.onMenuIconClick.bind(this, "/admin")}
              >
                <SettingsApplicationsIcon />
              </IconButton>
            )}
            <Typography variant="h6" className={classes.title}></Typography>
            {type !== UserTypes.NONE && <LogoutButton nodeRef={nodeRef} />}
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(MainMenu));
