import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./App.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "absolute",
    left: 0,
    bottom: 0,
    right: 0,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  vert: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
  },
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Information</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.vert}>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                Copyright notice
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                Plummet Ltd. trading as plummet.io.
                <br />
                Copyright © 2021 Plummet Ltd.
                <br />
                All rights reserved.
                <br />
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>How it works</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                With the 'Notify me' by Plummet skill enabled on your Alexa
                device, you can use this website to submit a message. Your Alexa
                device will receive a notification which you can hear by saying
                'Alexa..ask Notify Me for my message'.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                Why do I have to login with Amazon?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                This is needed so this website can send messages to your Alexa
                device.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                Receiving notifications
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                Using the Amazon Alexa app on your phone, look for Notify Me
                under 'Skills and Games'. In the settings, ensure you have
                linked the Notify Me application with your Amazon account and
                have enabled notifications.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Privacy</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                This site receives your name and email address when you log in,
                but it is only used for display purposes. The only data that is
                stored is your message and a unique user ID generated by Amazon.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Feedback</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                <span>
                  Any feedback or suggestions? Contact us:&nbsp;
                  <a href="mailto:info@plummet.io" target="_top">
                    info@plummet.io
                  </a>
                </span>
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}
