import React, { useState, useContext } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { green } from "@material-ui/core/colors";
import { UserContext } from "./Context.js";
import { MessageTypes } from "./MessageTypes.js";
import PythonText from './text/python_example.txt';
import CurlText from './text/curl_example.txt';
import "./App.css";


const { CognitoIdentityClient } = require("@aws-sdk/client-cognito-identity");
const {
  fromCognitoIdentityPool,
} = require("@aws-sdk/credential-provider-cognito-identity");
const { LambdaClient, InvokeCommand } = require("@aws-sdk/client-lambda");

// Set the AWS Region.
const REGION = "eu-west-1";

const bodyExample = '{"message":"your message here"}';
const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    padding: theme.spacing(6),
    display: "flex",
    flexWrap: "wrap",
    margin: "auto",
    justifyContent: "center",
  },
  code: {
    whiteSpace: 'pre-line',
    fontFamily: 'monospace'
  },
  section: {
    margin: "2em",
  },

  buttonRoot: {
    display: "flex",
    alignItems: "center",
  },
  textHeading: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  button: {
    margin: theme.spacing(1),
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
}));

const FormStates = {
  INITIAL: "initial",
  IN_FLIGHT: "in_flight",
  COMPLETE_EMPTY: "empty",
  COMPLETE_FULL: "full",
  NOT_LOGGED_IN: " not_logged_in",
};

function CodeBlock(props) {
  const classes = useStyles();
  return (
    <Paper>
      <Typography className={classes.code}>{props.text}</Typography>
    </Paper>
  );
}

function DevForm(props) {
  const context = useContext(UserContext);

  React.useEffect(() => {
    if (!context.AWSId) {
      setFormState(FormStates.NOT_LOGGED_IN);
    } else {
      getAPICredentials(context.AWSToken, context.AWSId).then(function (value) {
        if (value && value.APIKey && value.APISecret) {
          setAPIKey(value.APIKey);
          setAPISecret(value.APISecret);
          setFormState(FormStates.COMPLETE_FULL);
        } else {
          setFormState(FormStates.COMPLETE_EMPTY);
        }
      });
    }
  }, [context]);

  const [formState, setFormState] = useState(FormStates.INITIAL);
  const [showSecret, setShowSecret] = useState(false);
  const [apikey, setAPIKey] = useState("");
  const [apisecret, setAPISecret] = useState("");
  const classes = useStyles();
  const buttonClassname = clsx({
    [classes.buttonSuccess]: formState.COMPLETE_EMPTY,
  });

  function handleClickShowSecret() {
    setShowSecret(!showSecret);
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function handleSubmit(event) {
    event.preventDefault();

    if (formState === FormStates.COMPLETE_FULL) {
      deleteAPICredentials(context.AWSToken, context.AWSId).then(function (
        value
      ) {
        if (value) {
          setAPIKey("");
          setAPISecret("");
          setFormState(FormStates.COMPLETE_EMPTY);
        }
      });
    } else {
      requestAPICredentials(context.AWSToken, context.AWSId).then(function (
        value
      ) {
        if (value && value.APIKey && value.APISecret) {
          setAPIKey(value.APIKey);
          setAPISecret(value.APISecret);
          setFormState(FormStates.COMPLETE_FULL);
        }
      });
    }

    setFormState(FormStates.IN_FLIGHT);
  }
  return (
    <div className={classes.root}>
      {formState === FormStates.NOT_LOGGED_IN && (
        <a href="\">Please login to access developer features</a>
      )}
      {formState === FormStates.INITIAL && <CircularProgress />}
      {formState !== FormStates.INITIAL &&
        formState !== FormStates.NOT_LOGGED_IN && (
          <>
            <Paper elevation={3}>
              <p className={classes.textHeading}>
                Developer API access details
              </p>
              <form className="Msg-form" onSubmit={handleSubmit}>
                <Grid container spacing={1} className="fix-grid">
                  <Grid item lg={12}>
                    <TextField
                      id="outlined-basic"
                      label="API key"
                      fullWidth
                      variant="outlined"
                      value={apikey}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item lg={12}>
                    <TextField
                      id="outlined-basic"
                      label="API secret"
                      fullWidth
                      variant="outlined"
                      value={apisecret}
                      disabled={true}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle secret visibility"
                              onClick={handleClickShowSecret}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showSecret ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      type={showSecret ? "text" : "password"}
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <div className={classes.buttonRoot}>
                      <div className={classes.wrapper}>
                        <Button
                          variant="contained"
                          color="primary"
                          className={buttonClassname}
                          disabled={
                            formState === FormStates.INITIAL ||
                            formState === FormStates.IN_FLIGHT
                          }
                          type="submit"
                          size="large"
                        >
                          {formState === FormStates.COMPLETE_FULL && (
                            <b>Delete</b>
                          )}
                          {formState === FormStates.COMPLETE_EMPTY && (
                            <b>Request</b>
                          )}
                          {formState === FormStates.IN_FLIGHT && <b>Working</b>}
                        </Button>
                        {formState === FormStates.IN_FLIGHT && (
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                          />
                        )}
                      </div>
                    </div>
                  </Grid>
                  <Grid item lg={12}>
                    {formState === FormStates.COMPLETE_EMPTY && (
                      <p className="Dev-output">
                        You do not have API credentials. You can request them
                        using the button above
                      </p>
                    )}
                    {formState === FormStates.COMPLETE_FULL && (
                      <p className="Dev-output">
                        You have API credentials. API details:
                        <br />
                        <br />
                        Endpoint: https://api.getnotify.me/submit
                        <br />
                        Authentication: Basic (username = API key, password =
                        API secret)
                        <br />
                        Format: json
                        <br />
                        Body: {bodyExample}
                        <br />
                        <br />
                        Curl example:
                        <br />
                        <br />
                        <CodeBlock text={CurlText} />
                        <br />
                        <br />
                        Python example:
                        <br />
                        <br />
                        <CodeBlock text={PythonText} />
                        <br />
                        <br />
                        If you no longer want these credentials you can delete
                        them using the button above.
                        <br />
                        <br />
                        Support is available <a href="mailto:info@alexanotify.com" target="_top">here</a>
                      </p>
                    )}
                    {formState !== FormStates.COMPLETE_FULL &&
                      formState !== FormStates.COMPLETE_EMPTY && (
                        <p className="Dev-output"></p>
                      )}
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </>
        )}
    </div>
  );
}

function getAPICredentials(accesstoken, userid) {
  console.log("getAPICredentials for user:" + userid);

  return new Promise((resolve, reject) => {

    const lambda = new LambdaClient({
      region: REGION,
      credentials: fromCognitoIdentityPool({
        client: new CognitoIdentityClient({ region: REGION }),
        identityPoolId: process.env.REACT_APP_POOL_ID,
        logins: {
          "www.amazon.com": accesstoken,
        },
      }),
    });

    var invokeRequest = {
      FunctionName: "tonyAlexaSkill",
      InvocationType: "RequestResponse",
      LogType: "None",
      Payload: JSON.stringify({
        message_type: MessageTypes.GET_CREDS,
        userid: userid,
      }),
    };

    lambda.send(new InvokeCommand(invokeRequest), function (error, data) {
      if (error) {
        console.log(error);
        return resolve(false);
      } else {
        const asciiDecoder = new TextDecoder('ascii');
        const creds = JSON.parse(asciiDecoder.decode(data.Payload));
        return resolve(creds);
      }
    });
  });
}

function requestAPICredentials(accesstoken, userid) {
  console.log("requestAPICredentials for user:" + userid);

  return new Promise((resolve, reject) => {
    var invokeRequest = {
      FunctionName: "tonyAlexaSkill",
      InvocationType: "RequestResponse",
      LogType: "None",
      Payload: JSON.stringify({
        message_type: MessageTypes.REQUEST_CREDS,
        userid: userid,
      }),
    };

    const lambda = new LambdaClient({
      region: REGION,
      credentials: fromCognitoIdentityPool({
        client: new CognitoIdentityClient({ region: REGION }),
        identityPoolId: process.env.REACT_APP_POOL_ID,
        logins: {
          "www.amazon.com": accesstoken,
        },
      }),
    });

    lambda.send(new InvokeCommand(invokeRequest), function (error, data) {
      if (error) {
        console.log(error);
        return resolve(false);
      } else {
        const asciiDecoder = new TextDecoder('ascii');
        const creds = JSON.parse(asciiDecoder.decode(data.Payload));
        return resolve(creds);
      }
    });
  });
}

function deleteAPICredentials(accesstoken, userid) {
  console.log("deleteAPICredentials for user:" + userid);

  return new Promise((resolve, reject) => {

    const lambda = new LambdaClient({
      region: REGION,
      credentials: fromCognitoIdentityPool({
        client: new CognitoIdentityClient({ region: REGION }),
        identityPoolId: process.env.REACT_APP_POOL_ID,
        logins: {
          "www.amazon.com": accesstoken,
        },
      }),
    });

    var invokeRequest = {
      FunctionName: "tonyAlexaSkill",
      InvocationType: "RequestResponse",
      LogType: "None",
      Payload: JSON.stringify({
        message_type: MessageTypes.DELETE_CREDS,
        userid: userid,
      }),
    };

    lambda.send(new InvokeCommand(invokeRequest), function (error, data) {
      if (error) {
        console.log(error);
        return resolve(false);
      } else {
        return resolve(true);
      }
    });
  });
}

export default DevForm;
