import React, { useState } from "react";
import { UserContext, UserTypes } from "./Context.js";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "./App.css";
import MainMenu from "./MainMenu.js";
import Admin from "./Admin.js";
import Header from "./Header.js";
import NewsBanner from "./NewsBanner.js";
import MsgForm from "./MsgForm.js";
import DevForm from "./DevForm.js";
import LoginForm from "./LoginForm.js";
import PrivForm from "./PrivForm.js";
import Footer from "./Footer.js";
import GetUserDetails from "./GetUserDetails.js";
require("dotenv").config();

function App(props) {
  const [adminDetails, setAdminDetails] = useState(null);
  const [userDetails, setUserDetails] = useState({
    name: null,
    type: UserTypes.NONE,
    nodeRef: null,
    AWSId: null,
    AWSToken: null,
    setContext: setContext,
  });

  async function setContext(contextDetails) {
    contextDetails.setContext = setContext;
    var loggedIn =
      userDetails.type === UserTypes.NONE &&
      contextDetails.type === UserTypes.USER;

    setUserDetails(contextDetails);

    if (loggedIn) {
      // User has just logged in. Check if we should promote them to admin
      GetUserDetails(contextDetails.AWSId, contextDetails.AWSToken).then(
        function (extraDetails) {
          if (extraDetails.Item.Admin) {
            contextDetails.type = UserTypes.ADMIN;
            setAdminDetails(extraDetails.adminDetails);
            setUserDetails(contextDetails);
          }
        }
      );
    }
  }

  return (
    <UserContext.Provider value={userDetails}>
      <Router>
        <div className="App">
          <MainMenu name="Hi" />
          <Header />
          <NewsBanner />
        </div>
        <Switch>
          <Route exact path="/">
            {userDetails.type !== UserTypes.NONE && <MsgForm />}
            {userDetails.type === UserTypes.NONE && <LoginForm />}
            <Footer />
          </Route>
          <Route path="/admin">
            <header className="App-header">
              <Admin details={adminDetails} />
            </header>
          </Route>
          <Route path="/developer">
            <DevForm />
          </Route>
          <Route path="/privacy/fr">
            <PrivForm />
          </Route>
        </Switch>
      </Router>
    </UserContext.Provider>
  );
}

export default App;
