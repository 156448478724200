import React, { useState } from "react";
import { UserContext, UserTypes } from "./Context.js";
import { Banner } from "material-ui-banner";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import "./App.css";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

function NewsBanner(props) {
  const classes = useStyles();
  const [showBanner, setShowBanner] = useState(true);

  function handleOnClose(event) {
    setShowBanner(false);
  }

  function handleOnClick(event) {
    setShowBanner(false);
    props.history.push("/developer");
  }

  return (
    <UserContext.Consumer>
      {(userDetails) => (
        <div className={classes.root}>
          <Divider />
          {userDetails.type !== UserTypes.NONE && showBanner && (
            <Banner
              buttonLabel="Developer"
              buttonOnClick={handleOnClick}
              onClose={handleOnClose}
              label="Developers: API keys are now available on the developer page"
            />
          )}
          {userDetails.type === UserTypes.NONE && showBanner && (
            <Banner
              onClose={handleOnClose}
              label="Developers: API keys are now available. Login then open the developer page"
            />
          )}
        </div>
      )}
    </UserContext.Consumer>
  );
}

export default withRouter(NewsBanner);
