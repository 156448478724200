import React from "react";
import { withRouter } from "react-router-dom";
import "./App.css";

class LogoutButton extends React.Component {
  constructor(props) {
    super(props);
    this.logout = this.logout.bind(this);
    this.state = { dummy: true };
  }

  logout() {
    console.log("Logout");
    this.props.nodeRef.props.triggerLogout();
    this.props.history.push("/");
  }

  render() {
    return (
      <div className="Logout-button">
        <button onClick={this.logout}>Logout</button>
      </div>
    );
  }
}

export default withRouter(LogoutButton);
