import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import "./App.css";

require("dotenv").config();

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  media: {
    height: 80,
  },
}));

function PrivForm(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Paper elevation={3}>
        <p className={classes.textHeading}>
          Termes et conditions, confidentialité
          <br />
          <br />
          Ce site reçoit votre nom et votre adresse e-mail lorsque vous vous
          connectez, mais il n'est utilisé qu'à des fins d'affichage.
          <br /> Les seules données stockées sont votre message et un ID
          utilisateur unique généré par Amazon.
        </p>
      </Paper>
    </div>
  );
}

export default PrivForm;
