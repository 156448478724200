import React from "react";

export const UserTypes = {
  NONE: "none",
  USER: "user",
  ADMIN: "admin",
};

export const UserContext = React.createContext({
  name: null,
  type: UserTypes.NONE,
  setUser: null,
});
