import React, { Component } from "react";
import SocialButton from "./SocialButton";
import { UserContext, UserTypes } from "./Context.js";

require("dotenv").config();

class LoginButton extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.nodeRef = null;
    this.onLoginSuccess = this.handleSocialLogin.bind(this);
    this.onLoginFailure = this.handleSocialLoginFailure.bind(this);
    this.onLogoutSuccess = this.handleSocialLogout.bind(this);
    this.onLogoutFailure = this.handleSocialLogoutFailure.bind(this);
  }

  handleSocialLogin = (user) => {
    const { setContext } = this.context;
    setContext({
      name: user._profile.name,
      type: UserTypes.USER,
      nodeRef: this.nodeRef,
      AWSId: user.profile.id,
      AWSToken: user._token.accessToken,
    });
  };

  handleSocialLoginFailure = (err) => {
    console.error(err);
  };

  handleSocialLogoutFailure = (err) => {
    console.error(err);
  };

  handleSocialLogout = () => {
    const { setContext } = this.context;
    setContext({
      name: null,
      type: UserTypes.NONE,
      nodeRef: null,
      AWSId: null,
      AWSToken: null,
    });
  };

  // Keeps track of the current provider. We currently only use Amazon
  setNodeRef(node) {
    if (node) {
      this.nodeRef = node;
    }
  }

  render() {
    return (
      <SocialButton
        provider="amazon"
        appId={process.env.REACT_APP_APP_ID}
        onLoginSuccess={this.handleSocialLogin}
        onLoginFailure={this.handleSocialLoginFailure}
        onLogoutSuccess={this.handleSocialLogout}
        getInstance={this.setNodeRef.bind(this)}
      >
        <img
          border="0"
          alt="Login with Amazon"
          src="https://images-na.ssl-images-amazon.com/images/G/01/lwa/btnLWA_gold_156x32.png"
          width="156"
          height="32"
        />
      </SocialButton>
    );
  }
}

export default LoginButton;
