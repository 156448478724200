import React from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import { green } from "@material-ui/core/colors";
import { UserContext } from "./Context.js";
import { MessageTypes } from "./MessageTypes.js";
import "./App.css";

// Set the AWS Region.
const REGION = "eu-west-1";

const { CognitoIdentityClient } = require("@aws-sdk/client-cognito-identity");
const {
  fromCognitoIdentityPool,
} = require("@aws-sdk/credential-provider-cognito-identity");
const { LambdaClient, InvokeCommand } = require("@aws-sdk/client-lambda");

const styles = (theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(4),
      height: theme.spacing(32),
    },
  },

  buttonRoot: {
    display: "flex",
    alignItems: "center",
  },
  textHeading: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  button: {
    margin: theme.spacing(1),
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
});

//    <TextField id="standard-basic" label="Standard" />

class MsgForm extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = { value: "", loading: false, success: false };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAWSResult = this.handleAWSResult.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleAWSResult(success) {
    console.log ("handleAWSResult")
    this.setState({ loading: false });
    this.setState({ success: success });
  }

  handleSubmit(event) {
    const { AWSId, AWSToken } = this.context;
    event.preventDefault();
    this.setState({ loading: true });
    var success = sendRequest(
      this.state.value,
      AWSId,
      AWSToken,
      this.handleAWSResult
    );
    console.log("sendRequest response:" + success);
  }

  render() {
    const { classes } = this.props;
    const buttonClassname = clsx({
      [classes.buttonSuccess]: this.state.success,
    });

    return (
      <div className={classes.root}>
        <Paper elevation={3}>
          <p className={classes.textHeading}>
            {" "}
            Enter a message to send to your Alexa device{" "}
          </p>
          <form className="Msg-form" onSubmit={this.handleSubmit}>
            <Grid container spacing={1}>
              <Grid item lg={9}>
                <TextField
                  id="outlined-basic"
                  label="Message"
                  fullWidth
                  variant="outlined"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item lg={3}>
                <div className={classes.buttonRoot}>
                  <div className={classes.wrapper}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={buttonClassname}
                      disabled={this.state.loading}
                      type="submit"
                      endIcon={<Icon>send</Icon>}
                      size="large"
                    >
                      Send
                    </Button>
                    {this.state.loading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </div>
              </Grid>
              <Grid item sm={12} className="Msg-output">
                {this.state.success && (
                  <p className="Msg-output">
                    Message sent successfully! Say 'Alexa..Ask Notify Me for my
                    message'
                  </p>
                )}
              </Grid>
            </Grid>
          </form>
        </Paper>
      </div>
    );
  }
}

function sendRequest(message, userid, accesstoken, handleAWSResult) {
  const lambda = new LambdaClient({
    region: REGION,
    credentials: fromCognitoIdentityPool({
      client: new CognitoIdentityClient({ region: REGION }),
      identityPoolId: process.env.REACT_APP_POOL_ID,
      logins: {
        "www.amazon.com": accesstoken,
      },
    }),
  });

  var invokeRequest = {
    FunctionName: "tonyAlexaSkill",
    InvocationType: "RequestResponse",
    LogType: "None",
    Payload: JSON.stringify({
      message_type: MessageTypes.SUBMIT,
      message: message,
      userid: userid,
    }),
  };

  lambda.send(new InvokeCommand(invokeRequest), function (error, data) {
    if (error) {
      console.log(error);
      handleAWSResult(false);
    } else {
      console.log("Submit success");
      handleAWSResult(true);
    }
  });
}

export default withStyles(styles)(MsgForm);
