import React from "react";
import Image from "react-bootstrap/Image";
import { makeStyles } from "@material-ui/core/styles";
import AlexaGif from "./images/echo-message.gif";
import Paper from "@material-ui/core/Paper";
import LoginButton from "./LoginButton";
import Grid from "@material-ui/core/Grid";

import "./App.css";

require("dotenv").config();

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    padding: theme.spacing(6),
    display: "flex",
    flexWrap: "wrap",
    margin: "auto",
    justifyContent: "center",
  },
  section: {
    margin: "2em",
  },
}));

function LoginForm(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper elevation={3}>
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid item xs>
            <p className={classes.section}>
              Send messages to your Alexa device.
              <br />
              Login with Amazon to get started
            </p>
          </Grid>
          <Grid item xs>
            <LoginButton className={classes.section} />
          </Grid>
          <Grid item xs>
            <Image src={AlexaGif} fluid />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

export default LoginForm;
