import React from "react";
import { UserContext, UserTypes } from "./Context.js";
import "./App.css";

require("dotenv").config();

function Header(props) {
  return (
    <UserContext.Consumer>
      {(userDetails) => (
        <header className="App-header">
          {userDetails.type !== UserTypes.NONE && (
            <p className="App-title">
              Welcome to Alexa Notify Me, {userDetails.name}
            </p>
          )}
          {userDetails.type === UserTypes.NONE && (
            <p className="App-title">Welcome to Alexa Notify Me</p>
          )}
        </header>
      )}
    </UserContext.Consumer>
  );
}

export default Header;
